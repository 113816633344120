const host = process.env.VUE_APP_API_SERVER

const routes = {
  user: host + '/api/client/user',
  semaphore: host + '/api/client/semaphore',
  driver: host + '/api/client/driver',
  simulation: host + '/api/client/simulation',
}

export default {
  routes
}
